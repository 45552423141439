<app-navbar></app-navbar>

<div class="container">
    <div class="form-content_register">
        <div class="form-header">
            <h3>Lịch sử truy cập hệ thống</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> -->
        </div>
        <br>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
                <div class="input-group">
                  <input type="text" style="height: 36px !important;" class="form-control" placeholder="Nhập nội dung tìm kiếm" [(ngModel)]="pageModel.search"
                    (keydown.enter)="loadData();" name="seach">&nbsp;
                  <div class="input-group-append">
                    <button class="btn btn-info" type="button" (click)="loadData();"><i class="fas fa-search fa-sm"></i>&nbsp;Tìm
                      kiếm</button>
                    <button class="btn btn-outline-secondary" type="button"
                      (click)="pageModel.search = '';loadData();"><i
                        class="fas fa-sync-alt fa-sm"></i>&nbsp;Làm mới</button>
                  </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12" style="margin-top: 20px;">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th class="text-center align-middle" style="width: 60px; background-color: #66ccff;">STT</th>
                            <th class="text-center align-middle" style="background-color: #66ccff;">Hành động</th>
                            <th class="text-center align-middle" style="background-color: #66ccff;">Nội dung</th>
                            <th class="text-center align-middle" style="background-color: #66ccff;">Ngày tạo</th>
                        </tr>
                    </thead>
                    <tbody style="overflow: scroll;">
                        <tr *ngFor="let item of target.data;let i = index;">
                            <td class="text-center align-middle">{{(pageModel.currentPage - 1) * pageModel.pageSize
                                + i + 1}}</td>
                            <td class="text-center align-middle">{{item.action}}</td>
                            <td class="align-middle">{{item.note}}</td>
                            <td class="text-center align-middle">{{item.dateCreate | date : 'dd/MM/yyyy HH:mm:ss a'}}</td>
                        </tr>
                        <tr *ngIf="target?.recordsCount === 0">
                            <td colspan="6">Không có dữ liệu.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div *ngIf="target?.recordsCount > 0" class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                <div style="float: left;">
                    Tổng số bản ghi: {{target?.recordsCount}}
                </div>
                <div style="float: right;">
                    <ngx-pagination (pageChange)="pageModel.currentPage = $event;loadData()"
                        (pageSize)="pageModel.pageSize = $event;loadData()" [currentPage]="pageModel.currentPage"
                        [totalPages]="target.totalPage">
                    </ngx-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="position: relative; max-width: 95vw;">
    <div class="shape-img2"><img src="{{path}}/assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="{{path}}/assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="{{path}}/assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="{{path}}/assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="{{path}}/assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="{{path}}/assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="{{path}}/assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="{{path}}/assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Signup Area -->