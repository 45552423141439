<app-navbar></app-navbar>

<!-- Admin portal Area-->
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-sm-10 col-md-6 col-lg-6 mt-3 bg-portal">
            <div class="container py-5">
                <div class="section-title my-5">
                    <h3>QUẢN TRỊ DỮ LIỆU CHUYÊN NGÀNH</h3>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPCONGTHUONG')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-00aeff">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{
                                        path
                                    }}/assets/img/svg/congthuong.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_CT }}">Lĩnh vực Công Thương </a>
                            </h6>
                            <p>Dữ liệu được cung cấp từ Sở Công Thương</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPTTTT')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-c679e3">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/thongtin.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_TTTT }}"
                                    >Lĩnh vực Thông tin và Truyền thông</a
                                >
                            </h6>
                            <p>
                                Dữ liệu được cung cấp từ Sở Thông tin và Truyền
                                thông
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPNONGNGHIEP')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-eb6b3d">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{
                                        path
                                    }}/assets/img/svg/nongnghiep.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_NN }}">Lĩnh vực Nông nghiệp
                                    và Phát triển nông thôn</a>
                            </h6>
                            <p>
                                Dữ liệu được cung cấp từ Sở Nông nghiệp và Phát
                                triển nông thôn
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPLAODONG')">
                        <div class="single-services-box-admin">
                            <div class="icon">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/laodong.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_LDTBXH }}"
                                    >Lĩnh vực Lao động, thương binh và xã hội</a
                                >
                            </h6>
                            <p>
                                Dữ liệu được cung cấp từ Sở Lao động, thương
                                binh và xã hội
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPTAICHINH')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-cdf1d8">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/taichinh.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_TC }}">Lĩnh vực Tài chính</a>
                            </h6>
                            <p>Dữ liệu được cung cấp từ Sở Tài chính</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPNOIVU')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-00aeff">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/noivu.svg"
                                    alt="image"
                                />
                            </div>
                            <h6><a href="{{ WEB_NV }}">Lĩnh vực Nội vụ</a></h6>
                            <p>Dữ liệu được cung cấp từ Sở Nội vụ</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPXAYDUNG')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-f78acb">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/xaydung.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_XD }}">Lĩnh vực Xây dựng</a>
                            </h6>
                            <p>Dữ liệu được cung cấp từ Sở Xây dựng</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPVHTT')">
                        <div class="single-services-box-admin">
                            <div class="icon">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/vhtt.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_VHTT }}"
                                    >Lĩnh vực Văn hóa, thể thao và du lịch</a
                                >
                            </h6>
                            <p>
                                Dữ liệu được cung cấp từ Sở Văn hóa, thể thao và
                                du lịch
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPKHCN')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-eb6b3d">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/khoahoc.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_KHCN }}"
                                    >Lĩnh vực Khoa học và Công nghệ</a
                                >
                            </h6>
                            <p>
                                Dữ liệu được cung cấp từ Sở Khoa học và Công
                                nghệ
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPTUPHAP')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-f78acb">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/tuphap.svg"
                                    alt="image"
                                />
                            </div>
                            <h6><a href="{{ WEB_TP }}">Lĩnh vực Tư pháp</a></h6>
                            <p>Dữ liệu được cung cấp từ Sở Tư pháp</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPGIAODUCDAOTAO')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-eb6b3d">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/giaoduc.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_GDDT }}"
                                    >Lĩnh vực Giáo dục và Đào tạo</a
                                >
                            </h6>
                            <p>
                                Dữ liệu được cung cấp từ Sở Giáo dục và Đào tạo
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPYTE')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-00aeff">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/yte.svg"
                                    alt="image"
                                />
                            </div>
                            <h6><a href="{{ WEB_YT }}">Lĩnh vực Y tế </a></h6>
                            <p>Dữ liệu được cung cấp từ Sở Y tế</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPNGOAIVU')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-00aeff">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/ngoaivu.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_NGOAIVU }}"
                                    >Lĩnh vực Ngoại vụ
                                </a>
                            </h6>
                            <p>Dữ liệu được cung cấp từ Sở Ngoại vụ</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPTNMT')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-00aeff">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{
                                        path
                                    }}/assets/img/svg/tainguyen.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_TNMT }}"
                                    >Lĩnh vực Tài Nguyên & Môi Trường
                                </a>
                            </h6>
                            <p>
                                Dữ liệu được cung cấp từ Sở Tài Nguyên & Môi
                                Trường
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPUBND')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-00aeff">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{
                                        path
                                    }}/assets/img/svg/Logo_Tuyen_Quang.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_CONGBAODT }}"
                                    >Công Báo điện tử
                                </a>
                            </h6>
                            <p>
                                Dữ liệu được cung cấp từ Văn Phòng UBND tỉnh
                                Tuyên Quang
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="showAppPortal('APPQUANTRIDANHMUC')">
                        <div class="single-services-box-admin">
                            <div class="icon bg-00aeff">
                                <img
                                    class="imgSvg-linh-vuc"
                                    src="{{ path }}/assets/img/svg/danhmuc.svg"
                                    alt="image"
                                />
                            </div>
                            <h6>
                                <a href="{{ WEB_DMDC }}"
                                    >Danh mục dùng chung & Dữ liệu số hóa TTHC
                                </a>
                            </h6>
                            <p>Dữ liệu được cung cấp từ NGSP</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-10 col-md-3 col-lg-3 d-flex justify-content-center mt-3">
            <div
                class="quan-tri-hthong bg-portal text-center p-2 pt-5 justify-content-center"
            >
                <div class="section-title">
                    <h3 class="my-5">QUẢN TRỊ HỆ THỐNG</h3>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="quan-tri-chung p-1 pt-5">
                        <img
                            src="{{ path }}/assets/img/png/icon_admin.png"
                            alt="icon"
                            style="width: 75px"
                            class="pointer mb-3"
                            routerLink="/qtht"
                        />
                        <h5 class="pointer" routerLink="/qtht">QUẢN TRỊ CHUNG</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Admin portal Area -->

<div style="position: relative; max-width: 95vw">
    <div class="shape-img2">
        <img src="{{ path }}/assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="{{ path }}/assets/img/shape/shape3.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="{{ path }}/assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="{{ path }}/assets/img/shape/shape5.png" alt="image" />
    </div>
    <div class="shape-img7">
        <img src="{{ path }}/assets/img/shape/shape7.png" alt="image" />
    </div>
    <div class="shape-img8">
        <img src="{{ path }}/assets/img/shape/shape8.png" alt="image" />
    </div>
    <div class="shape-img9">
        <img src="{{ path }}/assets/img/shape/shape9.png" alt="image" />
    </div>
    <div class="shape-img10">
        <img src="{{ path }}/assets/img/shape/shape10.png" alt="image" />
    </div>
</div>
