import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-services-style-two',
  templateUrl: './services-style-two.component.html',
  styleUrls: ['./services-style-two.component.scss']
})
export class ServicesStyleTwoComponent implements OnInit {

  constructor() { }
  path: string = environment.APP_HOME_PATH;
  ngOnInit(): void {
  }

}
