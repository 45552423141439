import { ApiResponse,
    AppQthtApiService,
    AuthenticateRequest,
    AuthenticateResponseApiResponse,
    InsertUpdateModel,
    ListAppModelListApiResponse,
    ModelThongkeListApiResponse
} from '../../../common/app-qtht.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeDemoSixService {

  constructor(
    private _api: AppQthtApiService
  ) { }

  AuthAuthenhome(body: AuthenticateRequest): Observable<AuthenticateResponseApiResponse> {
    return this._api.appAuthApiV1AuthAuthenHome(body);
  }

  Insertuser(body: InsertUpdateModel): Observable<ApiResponse> {
    return this._api.appQthtApiV1DangkychiaseInsertUser(body);
  }

  ThongKeTheoDonVi(): Observable<ModelThongkeListApiResponse> {
    return this._api.appQthtApiV1SysUserGetThongKeTheoDonVi();
  }

  ThongKeTheoNam(): Observable<ModelThongkeListApiResponse> {
    return this._api.appQthtApiV1SysUserGetThongKeTheoNam();
  }

  ThongKeTheoLinhVuc(): Observable<ModelThongkeListApiResponse> {
    return this._api.appQthtApiV1SysUserGetThongKeTheoLinhVuc();
  }

  getListMenuAdmin(): Observable<ListAppModelListApiResponse> {
    return this._api.appQthtApiV1MenuListApp();
  }
}
