import { AuthService } from './../../../common/auth.service';
import { CommonService } from './../../../common/common.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnInit {

  constructor(
    private router: Router,
    private commonService: CommonService,
    private authService: AuthService,
  ) {
    this.jwtHelper = new JwtHelperService();
    this.jwtHelper.tokenGetter = () => this.token;
  }
  path: string = environment.APP_HOME_PATH;
  private jwtHelper: JwtHelperService;
  private token?: string;
  user = {
    username: null,
    password: null,
    appId: null
  };
  inputType = 'password';
  showHideClass = 'fa fa-eye';

  showPassword() {
    if (this.user.password !== null) {
      if(this.inputType === 'password') {
        this.inputType = 'text';
        this.showHideClass = 'fa fa-eye-slash';
      } else {
        this.inputType = 'password';
        this.showHideClass = 'fa fa-eye';
      }
    }
  };

  ngOnInit() { 
    if (!this.authService.checkTokenExpired()) {
      this.router.navigate(['/index']);
    }
  }

  login() {
    this.user.appId = 425;
    this.authService.login(this.user, null).subscribe(() => { },
      error => {
        this.commonService.toastrDanger('Đăng nhập thất bại: ' + error);
      }
    );
  }

}
