import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppQthtApiService, ChangePasswordModel } from 'src/app/common/app-qtht.service';
import { AuthService } from 'src/app/common/auth.service';
import { CommonService } from 'src/app/common/common.service';
import { SUCCESS_RESPONSE } from 'src/app/common/contants';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit { 

    constructor(
      private qthtService: AppQthtApiService,
      private commonService: CommonService,
      private router: Router,
      private authService: AuthService,
    ){}

    target = new ChangePasswordModel();
    path: string = environment.APP_HOME_PATH;
    rePassword: string = '';
    user = this.authService.getUser();
    ngOnInit(): void {
      
    }

    checkRepassword() {
        if (this.target.newPassword === '' || this.rePassword === '') {
          return false;
        }
        if (this.target.newPassword === this.rePassword)
          return false;
        else
          return true;
      }

    save(){
      this.target.userId = this.user.UserId;
      this.qthtService.appQthtApiV1SysUserChangePassword(this.target).subscribe((result) => {
        if (result.code === SUCCESS_RESPONSE) {
          this.commonService.toastrSuccess();
          this.authService.logout();
          this.user = null;
          this.router.navigate(['/login']).then(() => {
            this.commonService.toastrSuccess('Đăng nhập thành công !');
          });
        } else {
          this.commonService.toastrDanger(result.message);
        }
      });
    }

    inputType = 'password';
    showHideClass = 'fa fa-eye';
    inputTypeMK = 'password';
    showHideClassMK = 'fa fa-eye';
    inputTypeRe = 'password';
    showHideClassRe = 'fa fa-eye';
  
    showPassword() {
      if (this.target.oldPassword !== null) {
        if(this.inputType === 'password') {
          this.inputType = 'text';
          this.showHideClass = 'fa fa-eye-slash';
        } else {
          this.inputType = 'password';
          this.showHideClass = 'fa fa-eye';
        }
      }
    };
  
    showPasswordMK() {
      if (this.target.newPassword !== null) {
        if(this.inputTypeMK === 'password') {
          this.inputTypeMK = 'text';
          this.showHideClassMK = 'fa fa-eye-slash';
        } else {
          this.inputTypeMK = 'password';
          this.showHideClassMK = 'fa fa-eye';
        }
      }
    };

    showPasswordRe() {
        if (this.rePassword !== null) {
          if(this.inputTypeRe === 'password') {
            this.inputTypeRe = 'text';
            this.showHideClassRe = 'fa fa-eye-slash';
          } else {
            this.inputTypeRe = 'password';
            this.showHideClassRe = 'fa fa-eye';
          }
        }
      };
}
