<app-navbar></app-navbar>

<div class="row" style="height: 350px; width: 100%;margin-top: 30px;padding: 0px 140px">
    <div class="col-6">
        <div class="cs-banner tile-center">
            <div style="text-align: center;">
                <h4 (click)="duLieuChung()" class="pointer">Cổng dữ liệu mở (Open Data)</h4>
                <p style="margin-bottom: 0px;">Dữ liệu có thể được sử dụng một cách rộng rãi, bởi bất kỳ ai mà </p>
                <p> không cần xin bản quyền hay giấy phép để sử dụng chúng.</p>
                <img style="width: 280px;" src="{{path}}/assets/img/png/digital.png" alt="image" (click)="duLieuChung()"
                    class="pointer">
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="tile-center">
            <div style="text-align: center;">
                <h4 routerLink="/login-admin" class="pointer">Cơ sở dữ liệu dùng chung (Data warehouse)</h4>
                <p style="margin-bottom: 0px;">Data warehouse (DW) hay kho dữ liệu là một hệ thống lưu </p>
                <p>trữ dữ liệu từ nhiều nguồn, nhiều môi trường khác nhau </p>
                <img style="width: 280px;" src="{{path}}/assets/img/png/Data-Warehouse.png" alt="image"
                    routerLink="/login-admin" class="pointer">
            </div>
        </div>
    </div>
</div>

<!-- Start Services Area -->
<section class="services-area bg-F4F7FC">
    <div class="container">
        <div class="section-title" style="margin-bottom: 0px;" #scrollToElement>
            <h3>Dữ liệu theo lĩnh vực</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/congthuong.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_CT}}">Lĩnh vực Công Thương </a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Công Thương</p>
                    <a href="{{WEB_CT}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/thongtin.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_TTTT}}">Lĩnh vực Thông tin và Truyền thông</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Thông tin và Truyền thông</p>
                    <a href="{{WEB_TTTT}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/nongnghiep.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_NN}}"></a>Lĩnh vực Nông nghiệp và Phát triển nông thôn</h3>
                    <p>Dữ liệu được cung cấp từ Sở Nông nghiệp và Phát triển nông thôn</p>
                    <a href="{{WEB_NN}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/laodong.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_LDTBXH}}">Lĩnh vực Lao động, thương binh và xã hội</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Lao động, thương binh và xã hội</p>
                    <a href="{{WEB_LDTBXH}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/taichinh.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_TC}}">Lĩnh vực Tài chính</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Tài chính</p>
                    <a href="{{WEB_TC}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/noivu.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_NV}}">Lĩnh vực Nội vụ</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Nội vụ</p>
                    <a href="{{WEB_NV}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/xaydung.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_XD}}">Lĩnh vực Xây dựng</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Xây dựng</p>
                    <a href="{{WEB_XD}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/vhtt.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_VHTT}}">Lĩnh vực Văn hóa, thể thao và du lịch</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Văn hóa, thể thao và du lịch</p>
                    <a href="{{WEB_VHTT}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/khoahoc.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_KHCN}}">Lĩnh vực Khoa học và Công nghệ</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Khoa học và Công nghệ</p>
                    <a href="{{WEB_KHCN}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/tuphap.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_TP}}">Lĩnh vực Tư pháp</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Tư pháp</p>
                    <a href="{{WEB_TP}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/giaoduc.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_GDDT}}">Lĩnh vực Giáo dục và Đào tạo</a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Giáo dục và Đào tạo</p>
                    <a href="{{WEB_GDDT}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/yte.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_YT}}">Lĩnh vực Y tế </a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Y tế</p>
                    <a href="{{WEB_YT}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/ngoaivu.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_NGOAIVU}}">Lĩnh vực Ngoại vụ </a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Ngoại vụ</p>
                    <a href="{{WEB_NGOAIVU}}" class="read-more-btn">Xem chi tiết <i
                            class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/tainguyen.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_TNMT}}">Lĩnh vực Tài Nguyên & Môi Trường </a></h3>
                    <p>Dữ liệu được cung cấp từ Sở Tài Nguyên & Môi Trường</p>
                    <a href="{{WEB_TNMT}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_CONGBAODT}}">Công Báo điện tử </a></h3>
                    <p>Dữ liệu được cung cấp từ Văn Phòng UBND tỉnh Tuyên Quang</p>
                    <a href="{{WEB_CONGBAODT}}" class="read-more-btn">Xem chi tiết <i
                            class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <img class="imgSvg" src="{{path}}/assets/img/svg/danhmuc.svg" alt="image">
                    </div>
                    <h3><a href="{{WEB_DMDC}}">Danh mục dùng chung & Dữ liệu số hóa TTHC </a></h3>
                    <p>Dữ liệu được cung cấp từ NGSP</p>
                    <a href="{{WEB_DMDC}}" class="read-more-btn">Xem chi tiết <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->



<section class="services-area bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <h4>Biểu đồ thống kê theo đơn vị</h4>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <kendo-chart renderAs="canvas" *ngIf="lstDataThongKeDonVi.length > 0" [pannable]="true"
                    [zoomable]="true" [categoryAxis]="categoryAxis" [valueAxis]="valueAxis">
                    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                    <kendo-chart-tooltip [shared]="true"></kendo-chart-tooltip>
                    <kendo-chart-series>
                        <kendo-chart-series-item name="Số lượng" type="column" [data]="lstDataThongKeDonVi"
                            field="soluong" categoryField="ten" color="rgb(14, 93, 138)">
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>
        </div>
    </div>

    <div class="container mt-4">
        <div class="section-title">
            <h4>Biểu đồ thống kê theo năm</h4>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <kendo-chart renderAs="canvas" *ngIf="lstDataThongKeTheoNam.length > 0" [pannable]="true"
                    [zoomable]="true" [categoryAxis]="categoryAxis" [valueAxis]="valueAxis">
                    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                    <kendo-chart-tooltip [shared]="true"></kendo-chart-tooltip>
                    <kendo-chart-series>
                        <kendo-chart-series-item name="Số lượng" type="column" [data]="lstDataThongKeTheoNam"
                            field="soluong" categoryField="ten" color="rgb(14, 93, 138)">
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>
        </div>
    </div>

    <div class="container mt-4">
        <div class="section-title">
            <h4>Biểu đồ thống kê theo đơn vị</h4>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <kendo-chart renderAs="canvas" *ngIf="lstDataThongKeTheoLinhVuc.length > 0" [pannable]="true"
                    [zoomable]="true" [categoryAxis]="categoryAxis" [valueAxis]="valueAxis">
                    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                    <kendo-chart-tooltip [shared]="true"></kendo-chart-tooltip>
                    <kendo-chart-series>
                        <kendo-chart-series-item name="Số lượng" type="column" [data]="lstDataThongKeTheoLinhVuc"
                            field="soluong" categoryField="ten" color="rgb(14, 93, 138)">
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>
        </div>
    </div>

</section>





<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Dữ liệu chia sẻ</p>
                    <div class="back-text"></div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="16">00</span></h3>
                    <p>Đơn vị chia sẻ</p>
                    <div class="back-text"></div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Các liên kết</p>
                    <div class="back-text"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- Start Partner Area -->
<div class="partner-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <h2>Liên kết cổng thông tin các đơn vị</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">

            <div class="cs-partner-item">
                <a href="http://skhcn.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Khoa học và Công nghệ</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://snntuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Nông nghiệp và phát triển nông thôn</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://stttt.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Thông tin và Truyền thông</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://tuphaptuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/quochuy.svg" alt="image">
                    <p style="text-align: center;">Sở Tư pháp</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://sovanhoa.hoabinh.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Văn hóa, Thể thao và Du lịch</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://soxaydung.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Xây dựng</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://thanhtra.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/png/ttr.png" alt="image">
                    <p style="text-align: center;">Thanh tra tỉnh</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://sogiaothongvantai.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Giao thông Vận tải</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://sonoivu.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/quochuy.svg" alt="image">
                    <p style="text-align: center;">Sở Nội vụ</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://dofa.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Kế hoạch và Đầu tư</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="https://sokehoach.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Kế hoạch và Đầu tư</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://sotaichinh.tuyenquang.gov.vn/" target="_blank">
                    <img style="width: 68% !important;" src="{{path}}/assets/img/png/quochuy.png" alt="image">
                    <p style="text-align: center;">Sở Tài Chính</p>
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="http://soldtbxh.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/png/ldtbxh.png" alt="image">
                    <p style="text-align: center;">Sở Lao động - Thương binh và Xã hội</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://socongthuong.tuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Công Thương</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://tuyenquang.edu.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Giáo dục và Đào tạo</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://soytetuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Y tế</p>
                </a>
            </div>

            <div class="cs-partner-item">
                <a href="http://tnmttuyenquang.gov.vn/" target="_blank">
                    <img class="imgPng" src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="image">
                    <p style="text-align: center;">Sở Tài nguyên và Môi trường</p>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area pb-100">
    <div class="container">
    </div>
</section>
<!-- End Subscribe Area -->