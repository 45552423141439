<app-navbar></app-navbar>

<div class="container">
    <div class="form-content">
        <div class="form-header">
            <h3>Đổi mật khẩu</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> -->
        </div>
        <form #frm="ngForm" (ngSubmit)="save()">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 ">
                    <div class="form-group">
                        <label class="required">Mật khẩu cũ</label>
                        <input autocomplete="off" type="{{ inputType }}" class="form-control"
                            placeholder="Nhập lại mật khẩu" name="oldPassword" [(ngModel)]="target.oldPassword"
                            maxlength="50" #oldPassword="ngModel" required>
                        <span (click)="showPassword()">
                            <i style="position: relative; float: right; top: -35px; right: 20px; cursor: pointer;" class="{{showHideClass}}"></i>
                        </span>
                    </div>
                    <div *ngIf="oldPassword.errors && (oldPassword.dirty || oldPassword.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!oldPassword.errors.required">Mật khẩu cũ là bắt buộc</div>
                    </div>
                </div>
                <br>
                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                    <div class="form-group">
                        <label class="required">Mật khẩu cũ</label>
                        <input autocomplete="off" type="{{ inputTypeMK }}" class="form-control"
                            placeholder="Nhập lại mật khẩu" name="newPassword" [(ngModel)]="target.newPassword"
                            maxlength="50" #newPassword="ngModel" required>
                        <span (click)="showPasswordMK()">
                            <i style="position: relative; float: right; top: -35px; right: 20px; cursor: pointer;" class="{{showHideClassMK}}"></i>
                        </span>
                    </div>
                    <div *ngIf="newPassword.errors && (newPassword.dirty || newPassword.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!newPassword.errors.required">Mật khẩu mới là bắt buộc</div>
                    </div>
                </div>
                <br>
                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                    <div class="form-group">
                        <label class="required">Nhập lại mật khẩu</label>
                        <input autocomplete="off" type="{{ inputTypeRe }}" class="form-control"
                            placeholder="Nhập lại mật khẩu" name="rePassword" [(ngModel)]="rePassword"
                            maxlength="50" #repassword="ngModel" required>
                        <span (click)="showPasswordRe()">
                            <i style="position: relative; float: right; top: -35px; right: 20px; cursor: pointer;" class="{{showHideClassRe}}"></i>
                        </span>
                    </div>
                    <div *ngIf="repassword.errors && (repassword.dirty || repassword.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!repassword.errors.required">Nhập lại mật khẩu là bắt buộc</div>
                    </div>
                    <div *ngIf="checkRepassword()" class="alert alert-danger">
                        <div>Mật khẩu nhập lại không khớp</div>
                    </div>
                </div>
                <br>
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    <button type="submit" class="default-btn" [disabled]="frm.invalid">Lưu thông tin</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div style="position: relative; max-width: 95vw;">
    <div class="shape-img2"><img src="{{path}}/assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="{{path}}/assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="{{path}}/assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="{{path}}/assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="{{path}}/assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="{{path}}/assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="{{path}}/assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="{{path}}/assets/img/shape/shape10.png" alt="image"></div>
</div>


<!-- End Signup Area -->