<app-navbar></app-navbar>

<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Hướng dẫn tạo tài khoản</h2>
        </div>

        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p><b>Bước 1:</b> Người dùng truy cập vào <b>Kho dữ liệu và Cổng chuyển đổi số tỉnh Tuyên Quang</b></p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <img src="{{path}}/assets/img/services/hdaddacc0.png" alt="image">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p><b>Bước 2:</b> Người dùng click vào menu <b>Đăng nhập</b> , hệ thống hiển thị</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <img src="{{path}}/assets/img/services/hdaddacc2.png" alt="image">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p><b>Bước 3:</b> Đăng nhập/ Đăng ký tài khoản</p>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p>- Đối với người dùng đã có tài khoản đăng nhập vào hệ thống, nhập đầy đủ: Tên đăng nhập, Mật khẩu, sau đó click
                    <img width="110px" src="{{path}}/assets/img/services/hdaddacc3.png" alt="image"> để đăng nhập vào hệ thống.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p>- Đối với người dùng chưa có tài khoản đăng nhập. Người dùng click
                    <img width="110px" src="{{path}}/assets/img/services/hdaddacc3.png" alt="image"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p>+ Người dùng là cá nhân, chọn Tab <b>Cá nhân</b> , hệ thống hiển thị</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <img src="{{path}}/assets/img/services/hdaddacc4.png" alt="image">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p>+ Với người dùng là tổ chức, chọn Tab <b>Tổ chức</b> , hệ thống hiển thị</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <img src="{{path}}/assets/img/services/hdaddacc5.png" alt="image">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p>Nhập đầy đủ thông tin đăng ký, click
                    <img width="110px" src="{{path}}/assets/img/services/hdaddacc6.png" alt="image"> để đăng ký tài khoản đăng nhập hệ thống, sau đó quay lại Bước 2 thực hiện đăng nhập đối với người đã có tài khoản đăng nhập hệ thống.
                </p>
            </div>
        </div>
    </div>
</section>
