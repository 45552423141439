import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppQthtApiService, PageModel } from 'src/app/common/app-qtht.service';
import { AuthService } from 'src/app/common/auth.service';
import { CommonService } from 'src/app/common/common.service';
import { PAGE_SIZE, SUCCESS_RESPONSE } from 'src/app/common/contants';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-access-history',
    templateUrl: './access-history.component.html',
    styleUrls: ['./access-history.component.scss']
})
export class AccessHistoryComponent implements OnInit{ 

    constructor(
        private qthtService: AppQthtApiService,
        private commonService: CommonService,
        private router: Router,
        private authService: AuthService,
    ){}

    pageModel: PageModel = new PageModel({
        currentPage: 1,
        pageSize: PAGE_SIZE,
        userId: 0,
        search: null
    })

    target: any;
    path: string = environment.APP_HOME_PATH;
    user = this.authService.getUser();
    ngOnInit(): void {
        this.pageModel.userId = this.user.UserId;
        this.loadData();
    }

    loadData(){
        this.qthtService.appQthtApiV1SysLogActionPagingByUserId(this.pageModel).subscribe(res => {
            if(res.code === SUCCESS_RESPONSE){
                this.target = res.data
            }
        })
    }
}
