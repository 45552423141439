<app-navbar></app-navbar>
<div class="page-title-area">

</div>
<div class="container">
    <div class="form-content_register">
        <div class="form-header">
            <h3>Đăng ký chia sẻ dữ liệu</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> -->
        </div>
        <nav class="navbar navbar-expand-sm">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a [ngClass]="isleft === 'personal' ? 'nav-link active' : 'nav-link'"  (click)="toggleForm('personal')" href="javascript:void(0)">Cá nhân</a>
                </li>
                <li class="nav-item">
                  <a [ngClass]="isleft === 'organization' ? 'nav-link active' : 'nav-link '" (click)="toggleForm('organization')" href="javascript:void(0)">Tổ chức</a>
                </li>
            </ul>
        </nav>
        <div [ngClass]="isleft === 'personal' ? '' : 'd-none'" >
            <form #frm="ngForm" (ngSubmit)="save(1)">
                <div class="row">
                    <h4>I. Thông tin về dữ liệu</h4>
                    <br>
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 ">
                        <div class="form-group">
                            <label class="required">Tên tài liệu</label>
                            <input autocomplete="off" type="text" class="form-control" placeholder="Tên tài liệu"
                                name="tendulieu" [(ngModel)]="target.tendulieu" #tendulieu="ngModel" maxlength="50"
                                required>
                        </div>
                        <div *ngIf="tendulieu.errors && (tendulieu.dirty || tendulieu.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!tendulieu.errors.required">Tên tài liệu không được để trống</div>
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label class="required">Mô tả tài liệu</label>
                            <textarea class="form-control" name="motadulieu" maxlength="5000" [(ngModel)]="target.motadulieu"
                            required placeholder="Nhập nội dung" #motadulieu="ngModel" rows="10"></textarea>
                        </div>
                        <div *ngIf="motadulieu.errors && (motadulieu.dirty || motadulieu.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!motadulieu.errors.required">Mô tả tài liệu không được để trống</div>
                        </div>
                    </div>
                    <br />
                    <h4>II. Thông tin người đăng ký</h4>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label class="required">Họ tên</label>
                            <input type="text" class="form-control" placeholder="Họ tên" [(ngModel)]="target.hoten" id="fullname" name="fullname"
                            maxlength="50" #fullname="ngModel" required/>
                        </div>
                        <div *ngIf=" fullname.errors && (fullname.dirty || fullname.touched) " class="alert alert-danger" >
                            <div [hidden]=" !fullname.errors.required">
                                Họ tên không được để trống
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label class="required">Số điện thoại</label>
                            <input type="text" class="form-control" [(ngModel)]="target.sodt" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                name="phone" maxlength="11" #sodt="ngModel" placeholder="Số điện thoại" required/>
                        </div>
                        <div *ngIf=" sodt.errors && (sodt.dirty || sodt.touched)" class="alert alert-danger">
                            <div [hidden]=" !sodt.errors.required ">
                                Số điện thoại không được để trống
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label class="required">Email</label>
                            <input type="email" class="form-control" placeholder="Email" [pattern]="emailPattern"
                                name="email" [(ngModel)]="target.email" maxlength="200" #email="ngModel"
                                required> 
                        </div>
                        <div *ngIf=" email.errors && (email.dirty || email.touched)" class="alert alert-danger" >
                            <div [hidden]=" !email.errors.required">
                                Email không được để trống
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label> Đơn vị công tác</label>
                            <input type="text" placeholder="Đơn vị công tác" class="form-control" [(ngModel)]="target.donvicongtac" name="donvicongtac" maxlength="50">
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                        <input type="checkbox" [(ngModel)]="target.dieukhoan" name="dieukhoan"/>
                            <label>Tôi chịu trách nhiệm với thông tin đăng tải và chia sẻ dịch vụ</label>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end form-footer">
                        <button type="submit" class="default-btn" [disabled]="frm.invalid || !target.dieukhoan">Đăng ký</button>
                    </div>
                </div>
                <br />
            </form>
        </div>
        <div [ngClass]="isleft === 'organization' ? '' : 'd-none'">
            <form #frm2="ngForm" (ngSubmit)="save('Cá nhân')">
                <div class="row">
                    <h4>I. Thông tin về dữ liệu</h4>
                    <br>
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 ">
                        <div class="form-group">
                            <label class="required">Tên tài liệu</label>
                            <input autocomplete="off" type="text" class="form-control" placeholder="Tên tài liệu"
                                name="tendulieu" [(ngModel)]="target.tendulieu" #tendulieu="ngModel" maxlength="50"
                                required>
                        </div>
                        <div *ngIf="tendulieu.errors && (tendulieu.dirty || tendulieu.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!tendulieu.errors.required">Tên tài liệu không được để trống</div>
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label class="required">Mô tả tài liệu</label>
                            <textarea class="form-control" name="motadulieu" maxlength="5000" [(ngModel)]="target.motadulieu"
                            required placeholder="Nhập nội dung" #motadulieu="ngModel" rows="10"></textarea>
                        </div>
                        <div *ngIf="motadulieu.errors && (motadulieu.dirty || motadulieu.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!motadulieu.errors.required">Mô tả tài liệu không được để trống</div>
                        </div>
                    </div>
                    <br />
                    <h4>II. Thông tin người đại diện doanh nghiệp đăng ký</h4>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label class="required">Họ tên</label>
                            <input type="text" class="form-control" placeholder="Họ tên" [(ngModel)]="target.hoten" id="fullname" name="fullname"
                            maxlength="50" #fullname="ngModel" required/>
                        </div>
                        <div *ngIf=" fullname.errors && (fullname.dirty || fullname.touched) " class="alert alert-danger" >
                            <div [hidden]=" !fullname.errors.required">
                                Họ tên không được để trống
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label class="required">Số điện thoại</label>
                            <input type="text" class="form-control" [(ngModel)]="target.sodt" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                name="phone" maxlength="11" #sodt="ngModel" placeholder="Số điện thoại" required/>
                        </div>
                        <div *ngIf=" sodt.errors && (sodt.dirty || sodt.touched)" class="alert alert-danger">
                            <div [hidden]=" !sodt.errors.required ">
                                Số điện thoại không được để trống
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label class="required">Email</label>
                            <input type="email" class="form-control" placeholder="Email" [pattern]="emailPattern"
                                name="email" [(ngModel)]="target.email" maxlength="200" #email="ngModel"
                                required> 
                        </div>
                        <div *ngIf=" email.errors && (email.dirty || email.touched)" class="alert alert-danger" >
                            <div [hidden]=" !email.errors.required">
                                Email không được để trống
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label> Đơn vị công tác</label>
                            <input type="text" placeholder="Đơn vị công tác" class="form-control" [(ngModel)]="target.donvicongtac" name="donvicongtac" maxlength="50">
                        </div>
                    </div>
                    <br />
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="form-group">
                        <input type="checkbox" [(ngModel)]="target.dieukhoan" name="dieukhoan"/>
                            <label>Tôi chịu trách nhiệm với thông tin đăng tải và chia sẻ dịch vụ</label>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end form-footer">
                        <button type="submit" class="default-btn" [disabled]="frm.invalid || !target.dieukhoan">Đăng ký</button>
                    </div>
                </div>
                <br />
            </form>
        </div>
    </div>
</div>
<div style="position: relative; max-width: 95vw;">
    <div class="shape-img2"><img src="{{path}}/assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="{{path}}/assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="{{path}}/assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="{{path}}/assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="{{path}}/assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="{{path}}/assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="{{path}}/assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="{{path}}/assets/img/shape/shape10.png" alt="image"></div>
</div>