import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { CommonService } from "src/app/common/common.service";
import { AuthService } from "src/app/common/auth.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { SUCCESS_RESPONSE, UNITCODE_CLIENT } from "src/app/common/contants";

@Component({
    selector: "app-login-admin",
    templateUrl: "./login-admin.component.html",
    styleUrls: ["./login-admin.component.scss"],
})
export class LoginAdminComponent implements OnInit {
    user = {
        username: null,
        password: null,
        appId: null,
    };
    // lstApplication: any[] = [];
    path: string = environment.APP_HOME_PATH;

    constructor(
        private authService: AuthService,
        private commonService: CommonService,
        private router: Router
    ) {
        this.jwtHelper = new JwtHelperService();
        this.jwtHelper.tokenGetter = () => this.token;
    }
    private jwtHelper: JwtHelperService;
    private token?: string;
    loginSSO() {}

    inputType = "password";
    showHideClass = "fa fa-eye";

    showPassword() {
        if (this.user.password !== null) {
            if (this.inputType === "password") {
                this.inputType = "text";
                this.showHideClass = "fa fa-eye-slash";
            } else {
                this.inputType = "password";
                this.showHideClass = "fa fa-eye";
            }
        }
    }

    login() {
        this.user.appId = 101;
        this.authService.login(this.user, null).subscribe(
            (result) => {
                if (result.code === SUCCESS_RESPONSE && this.checkAdmin()) {
                    this.router.navigate(["/admin-portal"]);
                }
            },
            (error) => {
                this.commonService.toastrDanger("Đăng nhập thất bại: " + error);
            }
        );
    }

    checkAdmin() {
        const currentUser = this.authService.getUser();
        return currentUser &&
            currentUser.UnitCode &&
            currentUser.UnitCode != UNITCODE_CLIENT
            ? true
            : false;
    }

    createUser() {}

    ngOnInit(): void {
        if (!this.authService.checkTokenExpired() && this.checkAdmin()) {
            this.router.navigate(["/admin-portal"]);
        } else if (!this.authService.checkTokenExpired()) {
            this.router.navigate(["/index"]);
        }
    }
}
