// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APP_QTHT_URL: 'http://localhost:38020',
  APP_HOME_PATH: '',
  //path website
  // APP_QTHT_URL: 'https://apidata.tuyenquang.gov.vn',
  // APP_HOME_PATH: '/_layouts/15/opdt/portal',
  WEB_QTHT: '/qtht/',
  WEB_KHCN: '/khcn/',
  WEB_LDTBXH: '/ldtbxh/',
  WEB_NV: '/noivu/',
  WEB_TC: '/taichinh/',
  WEB_TTTT: '/thongtintt/',
  WEB_TP: '/tuphap/',
  WEB_VHTT: '/vhttdl/',
  WEB_GDDT: '/gddt/',
  WEB_NN: '/nongnghiep/',
  WEB_XD: '/xaydung/',
  WEB_YT: '/yte/',
  WEB_CT: '/congthuong/',
  WEB_TNMT: '/tnmt/',
  WEB_NGOAIVU: '/ngoaivu/',
  WEB_CONGBAODT: '/ubnd/',
  WEB_DMDC: '/dmdc/',
  // web admin 
  WEB_ADMIN_CT:'/congthuong/#/app-ct/admin/nghiep-vu/attp',
  WEB_ADMIN_QTHT: '/qtht/',
  WEB_ADMIN_KHCN: '/khcn/#/app-khcn/admin/nghiep-vu/ket-qua-linh-vuc-nghien-cuu/',
  WEB_ADMIN_LDTBXH: '/ldtbxh/#/ldtbxh/admin/ho-tro/',
  WEB_ADMIN_NV: '/noivu/#/app-snv/admin/nghiep-vu/so-luong-ho-so-luu-tru/',
  WEB_ADMIN_TC: '/taichinh/#/tai-chinh/admin/danh-muc-hang-hoa/',
  WEB_ADMIN_TTTT: '/thongtintt/#/thongtin-tt/admin/lanhdao/',
  WEB_ADMIN_TP: '/tuphap/#/app-stp/admin/van-phong/',
  WEB_ADMIN_VHTT: '/vhttdl/#/vhtt/admin/tl-diachi/',
  WEB_ADMIN_GDDT: '/gddt/#/app-gddt/admin/cong-tra-cuu-diem-thi-thpt-quoc-gia/',
  WEB_ADMIN_XD: '/xaydung/#/xay-dung/admin/danh-sach/gia-vlxd/',
  WEB_ADMIN_YT: '/yte/#/app-yte/admin/co-so-y-te/',
  WEB_ADMIN_TNMT: '/tnmt/#/tainguyen/admin/gia-dat-nong-nghiep/',
  WEB_ADMIN_NN: '/nongnghiep/#/app-nongnghiep/admin/sl-dt-luong-thuc',
  WEB_ADMIN_NGOAIVU: '/ngoaivu/#/app-ngv/admin/nghiep-vu/thong-ke/',
  WEB_ADMIN_CONGBAODT: '/ubnd/#/congbaodt/admin/cong-bao-dien-tu-tinh/',
  WEB_ADMIN_DMDC: '/dmdc/#/app-dmdc/admin/',

};

/*

 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
