import { environment } from "src/environments/environment";

export const SUCCESS_RESPONSE = 'Success';
export const DUPLICATE_RESPONE = 'Duplicate';
export const NOTFOUND_RESPONE = 'NotFound';
export const SUCCESS_NOTICE = 'Thành công';
export const TITLE_NOTICE = 'Thông báo';
export const PAGE_SIZE = 10;
export const UNITCODE_CLIENT = '322.99.99.999';
export const listSex = [
    {
        value: 1,
        text: 'Nam'
    },
    {
        value: 2,
        text: 'Nữ'
    },
    {
        value: 3,
        text: 'Khác'
    }
];
export const LBL_DISPLAY = {
  syncNgsp: 'Đồng bộ NGSP',
  add: 'Thêm mới',
  edit: 'Sửa',
  update: 'Cập nhập',
  delete: 'Xóa',
  view: 'Xem',
  search: 'Tìm kiếm',
  save: 'Lưu',
  print: 'In',
  close: 'Đóng',
  back: 'Quay lại',
  refresh: 'Làm mới',
  file: 'Chọn file',
  confirm: 'Xác nhận',
  cancel: 'Hủy',
  importFile: 'Import',
  downloadTemplate: 'Tải template',
  sync: 'Đồng bộ',
};

