import { AuthService } from "./../../../common/auth.service";
import { CommonService } from "./../../../common/common.service";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    path: string = environment.APP_HOME_PATH;
    ngOnInit() {}
}
