<app-navbar></app-navbar>
<div class="page-title-area">

</div>
<div class="container">
    <div class="form-content_register">
        <div class="form-header">
            <h3>Đăng ký tài khoản mới</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> -->
        </div>
        <nav class="navbar navbar-expand-sm">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a [ngClass]="isleft === 'personal' ? 'nav-link active' : 'nav-link'"  (click)="toggleForm('personal')" href="javascript:void(0)">Cá nhân</a>
                </li>
                <li class="nav-item">
                  <a [ngClass]="isleft === 'organization' ? 'nav-link active' : 'nav-link '" (click)="toggleForm('organization')" href="javascript:void(0)">Tổ chức</a>
                </li>
            </ul>
        </nav>
        <div [ngClass]="isleft === 'personal' ? '' : 'd-none'" >
            <form #frm="ngForm" (ngSubmit)="save(1)">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                        <div class="form-group">
                            <label class="required">Tên đăng nhập</label>
                            <input autocomplete="off" type="text" class="form-control" placeholder="Tên đăng nhập"
                                name="username" [(ngModel)]="target.username" #username="ngModel" maxlength="50"
                                required>
                        </div>
                        <div *ngIf="username.errors && (username.dirty || username.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!username.errors.required">Tên đăng nhập là bắt buộc</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label class="required">Họ và tên</label>
                            <input autocomplete="off" type="text" class="form-control" placeholder="Họ và tên"
                                name="fullname" [(ngModel)]="target.fullname" #fullname="ngModel" maxlength="50"
                                required>
                        </div>
                        <div *ngIf="fullname.errors && (fullname.dirty || fullname.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!fullname.errors.required">Họ và tên là bắt buộc</div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label class="required">Mật khẩu</label>
                            <input autocomplete="new-password" type="{{ inputType }}" class="form-control"
                                placeholder="Mật khẩu" name="password" [(ngModel)]="target.password" maxlength="50"
                                #password="ngModel">
                            <span (click)="showPassword()">
                                <i style="
                                                    position: relative;
                                                    float: right;
                                                    top: -35px;
                                                    right: 20px;
                                                    cursor: pointer;
                                                " class="{{ showHideClass }}"></i>
                            </span>
                        </div>
                        <div *ngIf="password.errors && (password.dirty || password.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!password.errors.required">Mật khẩu là bắt buộc</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label class="required">Nhập lại mật khẩu</label>
                            <input autocomplete="off" type="{{ inputTypeMK }}" class="form-control"
                                placeholder="Nhập lại mật khẩu" name="oldpassword" [(ngModel)]="rePassword"
                                maxlength="50" #repassword="ngModel" required>
                            <span (click)="showPasswordMK()">
                                <i style="
                                                    position: relative;
                                                    float: right;
                                                    top: -35px;
                                                    right: 20px;
                                                    cursor: pointer;
                                                " class="{{
                                                    showHideClassMK
                                                }}"></i>
                            </span>
                        </div>
                        <div *ngIf="repassword.errors && (repassword.dirty || repassword.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!repassword.errors.required">Nhập lại mật khẩu là bắt buộc</div>
                        </div>
                        <div *ngIf="checkRepassword()" class="alert alert-danger">
                            <div>Mật khẩu nhập lại không khớp</div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                        <div class="form-group">
                            <label class="required">Giới tính</label>
                            <select class="form-control"  [(ngModel)]="target.gioitinh" name="gioitinh"
                                #gioitinh="ngModel">
                                <option value="null" disabled selected>--- Chọn giới tính ---</option>
                                <option value="1">Nam</option>
                                <option value="0">Nữ</option>
                                <option value="2">Khác</option>
                            </select>
                        </div>
                        <div *ngIf="gioitinh.errors && (gioitinh.dirty || gioitinh.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!gioitinh.errors.required">Chọn giới tính</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label class="required">Số cccd / hộ chiếu</label>
                            <input autocomplete="off" type="text" class="form-control"
                                placeholder="Số cccd / hộ chiếu" maxlength="20" name="socmnd" #socmnd="ngModel"
                                [(ngModel)]="target.socmnd" required>
                        </div>
                        <div *ngIf="socmnd.errors && (socmnd.dirty || socmnd.touched)" class="alert alert-danger">
                            <div [hidden]="!socmnd.errors.required">Nhập số cccd / hộ chiếu</div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control" placeholder="Email" [pattern]="emailPattern"
                                name="email" [(ngModel)]="target.email" maxlength="200">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                        <div class="form-group">
                            <label class="required">Số điện thoại</label>
                            <input type="text" class="form-control" placeholder="Số điện thoại" name="phone"
                                [(ngModel)]="target.phone" maxlength="11" #phone="ngModel" required>
                        </div>
                        <div *ngIf="phone.errors && (phone.dirty || phone.touched)" class="alert alert-danger">
                            <div [hidden]="!phone.errors.required">Nhập số điện thoại</div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row ">
                    <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end form-footer">
                        <p>Đã có tài khoản? <a class="form-link" routerLink="/login">Đăng nhập</a></p>&nbsp;
                        <button type="submit" class="default-btn" [disabled]="frm.invalid || checkRepassword()">Đăng
                            ký</button>
                    </div>
                </div>
                <br />
            </form>
        </div>
        <div [ngClass]="isleft === 'organization' ? '' : 'd-none'">
            <form #frm2="ngForm" (ngSubmit)="save(2)">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                        <div class="form-group">
                            <label class="required">Tên đăng nhập</label>
                            <input autocomplete="off" type="text" class="form-control" placeholder="Tên đăng nhập"
                                name="username" [(ngModel)]="target.username" #username="ngModel" maxlength="50"
                                required>
                        </div>
                        <div *ngIf="username.errors && (username.dirty || username.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!username.errors.required">Tên đăng nhập là bắt buộc</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label class="required">Tên tổ chức</label>
                            <input autocomplete="off" type="text" class="form-control" placeholder="Tên tổ chức"
                                name="fullname" [(ngModel)]="target.fullname" #fullname="ngModel" maxlength="200"
                                required>
                        </div>
                        <div *ngIf="fullname.errors && (fullname.dirty || fullname.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!fullname.errors.required">Tên tổ chức là bắt buộc</div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                        <div class="form-group">
                            <label class="required">Họ và tên người liên hệ</label>
                            <input autocomplete="off" type="text" class="form-control" placeholder="Họ và tên người liên hệ"
                                name="nglienhe" [(ngModel)]="target.nglienhe" #nglienhe="ngModel" maxlength="50"
                                required>
                        </div>
                        <div *ngIf="nglienhe.errors && (nglienhe.dirty || nglienhe.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!nglienhe.errors.required">Họ và tên người liên hệ là bắt buộc</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label class="required">Mã số thuế</label>
                            <input autocomplete="off" type="text" class="form-control" placeholder="Mã số thuế"
                                name="msthue" [(ngModel)]="target.msthue" #msthue="ngModel" maxlength="50">
                        </div>
                        <div *ngIf="msthue.errors && (msthue.dirty || msthue.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!msthue.errors.required">Mã số thuế là bắt buộc</div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label class="required">Mật khẩu</label>
                            <input autocomplete="new-password" type="{{ inputType }}" class="form-control"
                                placeholder="Mật khẩu" name="password" [(ngModel)]="target.password" maxlength="50"
                                #password="ngModel">
                            <span (click)="showPassword()">
                                <i style="position: relative;float: right;top: -35px;right: 20px;cursor: pointer;" class="{{ showHideClass }}"></i>
                            </span>
                        </div>
                        <div *ngIf="password.errors && (password.dirty || password.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!password.errors.required">Mật khẩu là bắt buộc</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label class="required">Nhập lại mật khẩu</label>
                            <input autocomplete="off" type="{{ inputTypeMK }}" class="form-control"
                                placeholder="Nhập lại mật khẩu" name="oldpassword" [(ngModel)]="rePassword"
                                maxlength="50" #repassword="ngModel" required>
                            <span (click)="showPasswordMK()">
                                <i style="position: relative;float: right;top: -35px;right: 20px;cursor: pointer;" class="{{showHideClassMK}}"></i>
                            </span>
                        </div>
                        <div *ngIf="repassword.errors && (repassword.dirty || repassword.touched)"
                            class="alert alert-danger">
                            <div [hidden]="!repassword.errors.required">Nhập lại mật khẩu là bắt buộc</div>
                        </div>
                        <div *ngIf="checkRepassword()" class="alert alert-danger">
                            <div>Mật khẩu nhập lại không khớp</div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control" placeholder="Email" [pattern]="emailPattern"
                                name="email" [(ngModel)]="target.email" maxlength="200">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                        <div class="form-group">
                            <label class="required">Số điện thoại</label>
                            <input type="text" class="form-control" placeholder="Số điện thoại" name="phone"
                                [(ngModel)]="target.phone" maxlength="11" #phone="ngModel" required>
                        </div>
                        <div *ngIf="phone.errors && (phone.dirty || phone.touched)" class="alert alert-danger">
                            <div [hidden]="!phone.errors.required">Nhập số điện thoại</div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row ">
                    <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end form-footer">
                        <p>Đã có tài khoản? <a class="form-link" routerLink="/login">Đăng nhập</a></p>&nbsp;
                        <button type="submit" class="default-btn"
                            [disabled]="frm2.invalid || checkRepassword()">Đăng ký</button>
                    </div>
                </div>
                <br />
            </form>
        </div>
    </div>
</div>
<div style="position: relative; max-width: 95vw;">
    <div class="shape-img2"><img src="{{path}}/assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="{{path}}/assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="{{path}}/assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="{{path}}/assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="{{path}}/assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="{{path}}/assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="{{path}}/assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="{{path}}/assets/img/shape/shape10.png" alt="image"></div>
</div>